import React from 'react';

function GameDev() {
    return (
        <div>
            <h2>Game Development</h2>
            {/* Embed video demos, descriptions */}
        </div>
    );
}

export default GameDev;